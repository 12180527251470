import {
	ArrayField,
	ArrayInput,
	BulkDeleteButton,
	BulkExportButton,
	Create,
	Datagrid,
	DeleteButton,
	Edit,
	EditButton,
	List,
	NumberField,
	NumberInput,
	RadioButtonGroupInput,
	SimpleForm,
	SimpleFormIterator,
	SimpleList,
	TextField,
	TextInput,
} from 'react-admin';
import { CreateToolbar, PointsType, TopBarActions } from '../components';
import { Fragment } from 'react';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

const UgTitle = styled('span')({
	fontFamily: 'UgFont',
});

export const RegisterPointsList = (props) => {
	const isSmall = useMediaQuery('(max-width:600px)');

	const PostBulkActionButtons = (props) => (
		<Fragment>
			<BulkExportButton {...props} />
			{/* default bulk delete action */}
			<BulkDeleteButton {...props} />
		</Fragment>
	);

	return (
		<List {...props} >
			{isSmall ? (
				<SimpleList
					primaryText={(record) => (
						<span>
							{record.name.zh}{' '}
							<span className={UgTitle}>{record?.gift_points}</span>
						</span>
					)}
					secondaryText={(record) => record.type}
				/>
			) : (
				<Datagrid bulkActionButtons={<PostBulkActionButtons />}>
					<TextField source='name.zh' label='中文名称' />
					<TextField source='name.ug' label='维文名称' />
					<NumberField source='gift_points' label='兑换积分' />
					<PointsType source='type' label='类型' />
					<ArrayField source='details' label='选项'>
						<Datagrid>
							<TextField source='name.zh' label='选项中文名称' />
							<TextField source='name.ug' label='选项维文名称' />
						</Datagrid>
					</ArrayField>
					<EditButton />
					<DeleteButton />
				</Datagrid>
			)}
		</List>
	);
};

export const RegisterPointsEdit = (props) => {
	return (
		<Edit {...props} title='编辑等级' actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name.zh' label='中文名称' />
				<TextInput source='name.ug' label='维文名称' />
				<NumberInput source='gift_points' label='兑换积分' />
				<RadioButtonGroupInput
					label='类型'
					source='type'
					choices={[
						{ id: 0, name: '单选' },
						{ id: 1, name: '多选' },
					]}
				/>
				<ArrayInput source='details' label='选项'>
					<SimpleFormIterator inline>
						<TextInput
							label='选项中文名称'
							source='name.zh'
							helperText={false}
						/>
						<TextInput
							label='选项维文名称'
							source='name.ug'
							helperText={false}
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Edit>
	);
};

export const RegisterPointsCreate = (props) => {
	return (
		<Create {...props} title='新增积分选项' actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name.zh' label='中文名称' />
				<TextInput source='name.ug' label='维文名称' />
				<NumberInput source='gift_points' label='兑换积分' />
				<RadioButtonGroupInput
					label='类型'
					source='type'
					choices={[
						{ id: 0, name: '单选' },
						{ id: 1, name: '多选' },
					]}
				/>
				<ArrayInput source='details' label='选项'>
					<SimpleFormIterator inline>
						<TextInput
							label='选项中文名称'
							source='name.zh'
							helperText={false}
						/>
						<TextInput
							label='选项维文名称'
							source='name.ug'
							helperText={false}
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Create>
	);
};
