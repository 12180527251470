import { Button, useMediaQuery } from "@mui/material";
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  List,
  NumberField,
  SimpleList,
  BooleanField,
  Create,
  DateTimeInput,
  Edit,
  NumberInput,
  SimpleForm,
  useRecordContext,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { CreateToolbar, TopBarActions } from "./components";
import { currencyProps } from "./constants";

export const CouponsList = (props) => {
  const isSmall = useMediaQuery("(max-width:600px)");

  const ShowEdit = () => {
    const record = useRecordContext();
    console.log("🚀 ~ ShowEdit ~ record:", record)
    const navigate = useNavigate();
    return (
      <Button
        // startIcon={<VisibilityIcon />}
        style={{ width: 50, fontSize: 10 }}
        onClick={() => {
          localStorage.setItem("supermarketId", record.id);
          navigate({
            pathname: "/supermarket/show",
            state: { id: record.id },
          });
        }}
        variant="contained"
        color="primary"
        disableElevation
      >
        查看
      </Button>
    );
  };

  return (
    <>
      <List
        {...props}
        title={props.resource === "coupons" ? "商场优惠券" : "超市优惠券"}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) =>
              `满${record.bar_price / 100}减${record.face_price / 100}元`
            }
            secondaryText={(record) =>
              `补贴${record.company_allowance / 100}元`
            }
            tertiaryText={(record) => `${record.exchange_points}积分可兑换`}
          />
        ) : (
          <Datagrid rowClick={false}>
            <NumberField source="bar_price" label="满多少" {...currencyProps} />
            <NumberField
              source="face_price"
              label="优惠金额"
              {...currencyProps}
            />
            <NumberField
              source="company_allowance"
              label="补贴金额"
              {...currencyProps}
            />
            <NumberField source="exchange_points" label="兑换积分" />
            <BooleanField source="could_user_exchange" label="可否兑换" />
            <DateField source="start_at" label="开始日期" />
            <DateField source="end_at" label="结束日期" />
            <NumberField source="valid_time" label="有效时间（小时）" />
            <NumberField source="limit_per_user" label="每人限领（张）" />
            {props.resource === "coupons" ? null : <ShowEdit />}
            <EditButton />
            <DeleteButton confirmTitle="" mutationMode="pessimistic" />
          </Datagrid>
        )}
      </List>
    </>
  );
};

const CouponsTitle = ({ record }) => {
  let title = "优惠券";
  if (record && record.id) {
    title = `"满${record.bar_price}减${record.face_price}"`;
  }
  return <span>{title}</span>;
};

export const CouponsEdit = (props) => {
  return (
    <Edit {...props} title={<CouponsTitle />} actions={<TopBarActions />}>
      <SimpleForm>
        <DateTimeInput source="start_at" label="开始日期" required />
        <DateTimeInput source="end_at" label="结束日期" required />
        <NumberInput source="bar_price" label="满多少" required />
        <NumberInput source="face_price" label="优惠金额" required />
        <NumberInput source="company_allowance" label="补贴金额" />
        <NumberInput source="valid_time" label="有效时间（小时）" />
        <NumberInput source="limit_per_user" label="每人限领（张）" />
      </SimpleForm>
    </Edit>
  );
};

export const CouponsCreate = (props) => {
  return (
    <Create {...props} title={<CouponsTitle />} actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <DateTimeInput source="start_at" label="开始日期" required />
        <DateTimeInput source="end_at" label="结束日期" required />
        <NumberInput source="bar_price" label="满多少" required />
        <NumberInput source="face_price" label="优惠金额" required />
        <NumberInput source="company_allowance" label="补贴金额" />
        <NumberInput source="valid_time" label="有效时间（小时）" />
        <NumberInput source="limit_per_user" label="每人限领（张）" />
      </SimpleForm>
    </Create>
  );
};
