import {
	Datagrid,
	SimpleList,
	TextField,
	DateField,
	ListContextProvider,
	useListController,
	ImageField,
	Filter,
	DateInput,
	TextInput,
} from 'react-admin';
import { Pagination } from '../components';
import { useMediaQuery } from '@mui/material';
import useStyles from './PointHistoryStyle';
import { useLocation } from 'react-router-dom';

export const PointsUseList = (props) => {
	const state = useLocation();
	const pdate = state.pathname === '/ponit/use' ? state.date : '';
	const classes = useStyles(props);
	const isSmall = useMediaQuery('(max-width:600px)');

	return (
		<ListContextProvider
			title='会员使用积分明细'
			value={useListController({
				sort: { field: 'created_at', order: 'desc' },
				basePath: props.match?.path,
				resource: 'ponit/histories',
				filter: { type: 1 },
				filterDefaultValues: { start_at: pdate, end_at: pdate },
			})}
		>
			<div style={{ display: 'flex' }}>
				<div style={{ flex: '1' }}>
					<div>
						<Filter {...props}>
							<DateInput label='开始日期' source='start_at' />
							<DateInput label='结束日期' source='end_at' />
							<TextInput

								label='手机号搜索'
								source='mobile'
								resettable
							/>
						</Filter>
					</div>
				</div>
			</div>
			{isSmall ? (
				<SimpleList
					leftAvatar={(record) => (
						<div className={classes.avatar}>
							<img
								src={record?.wechat?.data.avatarUrl}
								alt={record?.wechat?.data.nickName}
							/>
						</div>
					)}
					primaryText={(record) => <span>积分{record?.amount} </span>}
					secondaryText={(record) => <span>时间 {record?.created_at}</span>}
				/>
			) : (
				<Datagrid>
					<div className={classes.avatarWrapper}>
						<ImageField
							className={classes.avatar}
							source='wechat.data.avatarUrl'
							label='用户头像'
						/>
						<TextField source='wechat.data.nickName' label='用户昵称' />
					</div>
					<TextField source='user.data.name' label='姓名' />

					<TextField source='user.data.mobile' label='电话' />
					<TextField source='amount' label='积分' />
					<TextField source='comment' label='使用途径' />
					<DateField
						source='created_at'
						label='消费日期+时间'
						locales='zh-CN'
						showTime
					/>
				</Datagrid>
			)}
			<Pagination />
		</ListContextProvider>
	);
};
