import {
	BooleanField,
	Create,
	Datagrid,
	DateField,
	DateInput,
	EditButton,
	Filter,
	List,
	NumberField,
	NumberInput,
	RadioButtonGroupInput,
	SimpleForm,
	SimpleList,
	TextField,
	TextInput,
	useRecordContext,
	BulkExportButton,
} from 'react-admin';
import { useMediaQuery, Drawer, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PointEdit from '../points/PointEdit';
import {
	CreateToolbar,
	FullNameField,
	Pagination,
	TopBarActions,
	UsersGender,
} from '../components';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
	},
	drawerPaper: {
		zIndex: 100,
	},
	list: {
		flexGrow: 1,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	simpleRoot: { width: '100vw' },
	filter: {
		margin: '2vh',
	},
}));

const MemberFilter = (props) => {
	return (
		<Filter {...props}>
			<div label='搜索' source='mobile' >
				<TextInput label='搜索' source='mobile' resettable />
			</div>

			<div label='注册日期' source='register'>
				<DateInput label='开始日期' source='register.start_at' />
				<DateInput label='结束日期' source='register.end_at' />
			</div>
			<div label='生日日期' source='birthday'>
				<DateInput label='生日开始' source='birthday.birthday_start' />
				<DateInput label='生日结束' source='birthday.birthday_end' />
			</div>
			<div label='积分' source='points'>
				<TextInput label='积分开始' source='points.points_start' />
				<TextInput label='积分结束' source='points.points_end' />
			</div>
		</Filter>
	);
};

const MemberEdit = ({ setRecord }) => {
	const record = useRecordContext();
	return (
		<Button
			style={{ width: 20, fontSize: 10 }}
			onClick={() => {
				record.type = 'member';
				setRecord(record);
			}}
			variant='contained'
			color='primary'
			disableElevation
		>
			会员信息
		</Button>
	);
};

const PointsEdit = ({ setRecord }) => {
	const record = useRecordContext();
	return (
		<Button
			style={{ width: 20, fontSize: 10 }}
			onClick={() => {
				record.type = 'decrement';
				setRecord(record);
			}}
			variant='contained'
			color='primary'
			disableElevation
		>
			清除积分
		</Button>
	);
};

const CustomEdit = ({ setRecord }) => {
	const record = useRecordContext();
	return (
		<Button
			style={{ width: 20, fontSize: 10 }}
			onClick={() => {
				record.type = 'supplement';
				setRecord(record);
			}}
			variant='contained'
			color='primary'
			disableElevation
		>
			补录积分
		</Button>
	);
};

export const MembersList = (props) => {
	const state = useLocation();
	const pdate = state.pathname === '/members' ? state.date : '';
	const classes = useStyles();
	const [record, setRecord] = useState(null);

	const isSmall = useMediaQuery('(max-width:600px)');
	const handleClose = () => {
		console.log('PointDelToolbar >>>> closed');
		setRecord(null);
	};
	useEffect(() => {
		if (record) {
			console.log('🚀 ~ MembersList ~ record:', record.type);
		}
	}, [record]);
	console.log('🚀 ~ MembersList ~ record:', record);

	const PostBulkActionButtons = (props) => {
		return (
			<Fragment>
				<BulkExportButton {...props} />
			</Fragment>
		);
	};
	return (
		<>
			<List
				{...props}
				pagination={<Pagination />}
				className={classes.list}
				filters={<MemberFilter />}
				title='会员管理'
				filterDefaultValues={pdate ? { start_at: pdate, end_at: pdate } : null}
			>
				{isSmall ? (
					<SimpleList
						className={classes.simpleRoot}
						primaryText={(r) => '姓名'}
						secondaryText={(r) => r.name}
						tertiaryText={(r) => `积分 ${r.points}`}
						linkType={(r) => (r.canEdit ? 'edit' : 'show')}
					/>
				) : (
					<Datagrid optimized bulkActionButtons={<PostBulkActionButtons />}>
						<FullNameField />
						<TextField source='name' label='姓名' />
						<DateField source='birthday' label='生日' />
						<UsersGender source='gender' label='性别' />
						<BooleanField
							source='is_wechat_points_enabled'
							label='商户圈积分授权'
						/>
						<TextField source='mobile' label='手机号' />
						<TextField source='license_plate_number' label='车牌号' />
						<TextField source='last_spent_time' label='最后消费时间' />
						<TextField source='register_from' label='注册来源' />
						<NumberField source='points' label='积分' />
						<CustomEdit setRecord={setRecord} />
						<PointsEdit setRecord={setRecord} />
						<MemberEdit setRecord={setRecord} />
					</Datagrid>
				)}
			</List>
			<Drawer
				variant='persistent'
				open={!!record}
				anchor='right'
				onClose={handleClose}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				{!!record ? (
					<PointEdit
						setRecord={setRecord}
						id={record.id}
						type={record.type}
						onClose={handleClose}
						resource={props.resource}
						basePath={props.basePath}
					/>
				) : null}
			</Drawer>
		</>
	);
	// return (
	// 	<Route path='/members/:id'>
	// 		{({ match }) => {
	// 			const isMatch = !!(
	// 				match &&
	// 				match.params &&
	// 				match.params.id !== 'create'
	// 			);
	// 		}}
	// 	</Route>
	// );
};

export const MembersCreate = (props) => {
	return (
		<Create {...props} title='新建会员' actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name' label='姓名' required />
				<DateInput source='birthday' label='生日' required />
				<RadioButtonGroupInput
					label='性别'
					source='gender'
					choices={[
						{ id: '1', name: '男' },
						{ id: '2', name: '女' },
					]}
					required
				/>
				<NumberInput source='mobile' label='手机号' required />
				<NumberInput source='points' label='积分' required />
			</SimpleForm>
		</Create>
	);
};
