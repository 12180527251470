import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BusinessIcon from '@mui/icons-material/Business';
import CakeIcon from '@mui/icons-material/Cake';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CategoryIcon from '@mui/icons-material/Category';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DevicesIcon from '@mui/icons-material/Devices';
import UserIcon from '@mui/icons-material/Group';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AssignmentIcon from '@mui/icons-material/Assignment';
import clsx from "clsx";
import { useState } from "react";
import { Menu, useSidebarState } from "react-admin";
import SubMenu from "./SubMenu";

const MenuContainer = styled('div')(({ theme, open }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingBottom: '20px',
  width: open ? 240 : 55,
}));

const MyMenu = () => {
  const [open] = useSidebarState();
  const [state, setState] = useState({
    menuCatalog: false,
    menuPermission: false,
    menuMember: false,
    menuGifts: false,
    menuPark: false,
    menuReport: false,
    menuSetting: false,
    menuAds: false,
    menuSurvey: false,
  });

  const auth = JSON.parse(localStorage.getItem("auth"));

  let manage_roles = false;
  let manage_admins = false;
  let manage_shops = false;
  let manage_advertisements = false;
  let manage_auditors = false;
  let manage_coupon = false;
  let manage_license = false;
  let manage_members = false;
  let manage_member_gifts = false;
  let manage_parking_coupon = false;
  let manage_supermarket_coupon = false;

  for (let i = 0; i < auth?.permission?.length; i++) {
    if (auth.permission[i] === "*") {
      manage_roles = true;
      manage_admins = true;
      manage_shops = true;
      manage_advertisements = true;
      manage_auditors = true;
      manage_coupon = true;
      manage_license = true;
      manage_members = true;
      manage_member_gifts = true;
      manage_parking_coupon = true;
      manage_supermarket_coupon = true;
      break;
    }
    if (auth.permission[i].name === "manage-roles") {
      manage_roles = true;
    }
    if (auth.permission[i].name === "manage-admins") {
      manage_admins = true;
    }
    if (auth.permission[i].name === "manage-shops") {
      manage_shops = true;
    }
    if (auth.permission[i].name === "manage-advertisements") {
      manage_advertisements = true;
    }
    if (auth.permission[i].name === "manage-auditors") {
      manage_auditors = true;
    }
    if (auth.permission[i].name === "manage-coupon") {
      manage_coupon = true;
    }
    if (auth.permission[i].name === "manage-license") {
      manage_license = true;
    }
    if (auth.permission[i].name === "manage-members") {
      manage_members = true;
    }
    if (auth.permission[i].name === "manage-member-gifts") {
      manage_member_gifts = true;
    }
    if (auth.permission[i].name === "manage-parking-coupon") {
      manage_parking_coupon = true;
    }
    if (auth.permission[i].name === "manage-supermarket-coupon") {
      manage_supermarket_coupon = true;
    }
  }

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <MenuContainer open={open}>
      <Menu.Item
        to="/data/member/info"
        primaryText="概览"
        leftIcon={<ViewComfyIcon />}

      />
      {manage_members ? (
        <SubMenu
          handleToggle={() => handleToggle("menuMember")}
          isOpen={state.menuMember}
          name="会员管理"
          icon={<CardMembershipIcon />}

        >
          <Menu.Item
            to="/members"
            primaryText="会员管理"
            leftIcon={<CardMembershipIcon />}

          />
          <Menu.Item
            to="/member/levels"
            primaryText="会员等级"
            leftIcon={<ViewStreamIcon />}

          />
          <Menu.Item
            to="/member/rights"
            primaryText="会员权益"
            leftIcon={<LockOpenIcon />}

          />
          <Menu.Item
            to="/member/service1v1"
            primaryText="会员1v1服务"
            leftIcon={<LockOpenIcon />}

          />
          <Menu.Item
            to="/member/birthdays"
            primaryText="会员今日生日提醒"
            leftIcon={<CakeIcon />}

          />
          <Menu.Item
            to="/birthday/week"
            primaryText="会员本周生日提醒"
            leftIcon={<CakeIcon />}

          />
          <Menu.Item
            to="/ponit/histories"
            primaryText="会员获取积分明细"
            leftIcon={<AccessTimeIcon />}

          />

          <Menu.Item
            to="/ponit/use"
            primaryText="会员使用积分明细"
            leftIcon={<AccessTimeIcon />}

          />
          <Menu.Item
            to="/data/member/growth"
            primaryText="会员增长统计"
            leftIcon={<AssessmentIcon />}

          />
          <Menu.Item
            to="/data/member/point/increment"
            primaryText="会员积分增长统计"
            leftIcon={<AssessmentIcon />}

          />
          <Menu.Item
            to="/data/member/point/usage"
            primaryText="会员积分使用统计"
            leftIcon={<AssessmentIcon />}

          />
          <Menu.Item
            to="/data/member/levels"
            primaryText="会员等级分析"
            leftIcon={<AssessmentIcon />}

          />
        </SubMenu>
      ) : null}
      {manage_member_gifts ? (
        <SubMenu
          handleToggle={() => handleToggle("menuGifts")}
          isOpen={state.menuGifts}
          name="礼品管理"
          icon={<CardGiftcardIcon />}

        >
          <Menu.Item
            to="/member/gifts"
            primaryText="会员礼品"
            leftIcon={<CardGiftcardIcon />}

          />
          <Menu.Item
            to="/member/gift/orders"
            primaryText="礼品订单"
            leftIcon={<AllInboxIcon />}

          />
        </SubMenu>
      ) : null}
      {manage_shops ? (
        <SubMenu
          handleToggle={() => handleToggle("menuCatalog")}
          isOpen={state.menuCatalog}
          name="店铺管理"
          icon={<StoreIcon />}

        >
          <Menu.Item
            to="/shop/industries"
            primaryText="店铺业态"
            leftIcon={<CategoryIcon />}

          />
          <Menu.Item
            to="/shop/categories"
            primaryText="店铺分类"
            leftIcon={<CategoryIcon />}

          />
          <Menu.Item
            to="/floors"
            primaryText="楼层"
            leftIcon={<BusinessIcon />}

          />
          <Menu.Item
            to="/shops"
            primaryText="店铺"
            leftIcon={<StoreIcon />}

          />
          {manage_auditors ? (
            <Menu.Item
              to="/auditors"
              primaryText="核销员"
              leftIcon={<PlaylistAddCheckIcon />}

            />
          ) : null}
        </SubMenu>
      ) : null}
      {manage_advertisements ? (
        <SubMenu
          handleToggle={() => handleToggle("menuAds")}
          isOpen={state.menuAds}
          name="广告文章管理"
          icon={<PhotoLibraryIcon />}

        >
          <Menu.Item
            to="/advertisements"
            primaryText="广告"
            leftIcon={<PhotoLibraryIcon />}

          />
          <Menu.Item
            to="/articles"
            primaryText="文章"
            leftIcon={<PhotoLibraryIcon />}

          />
        </SubMenu>
      ) : null}

      {manage_supermarket_coupon ? (
        <Menu.Item
          to="/supermarket-coupons"
          primaryText="超市优惠券"
          leftIcon={<ShoppingCartIcon />}

        />
      ) : null}
      {manage_coupon ? (
        <Menu.Item
          to="/coupons"
          primaryText="通用优惠券"
          leftIcon={<ConfirmationNumberIcon />}

        />
      ) : null}
      {manage_parking_coupon || manage_license ? (
        <SubMenu
          handleToggle={() => handleToggle("menuPark")}
          isOpen={state.menuPark}
          name="停车管理"
          icon={<LocalTaxiIcon />}

        >
          {manage_parking_coupon ? (
            <Menu.Item
              to="/parking/coupons"
              primaryText="停车优惠券"
              leftIcon={<LocalTaxiIcon />}

            />
          ) : null}
          {manage_license ? (
            <Menu.Item
              to="/parking/orders"
              primaryText="停车订单"
              leftIcon={<LocalParkingIcon />}

            />
          ) : null}
        </SubMenu>
      ) : null}
      <Menu.Item
        to="/point/activities"
        primaryText="积分活动管理"
        leftIcon={<LocalOfferIcon />}

      />
      <SubMenu
        handleToggle={() => handleToggle("menuSurvey")}
        isOpen={state.menuSurvey}
        name="问卷调查"
        icon={<AssignmentIcon />}

      >
        <Menu.Item
          to="/surveys"
          primaryText="问卷列表"
          leftIcon={<AssignmentIcon />}

        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuSetting")}
        isOpen={state.menuSetting}
        name="系统设置"
        icon={<PhonelinkSetupIcon />}

      >
        <Menu.Item
          to="/weapp"
          primaryText="小程序主页面"
          leftIcon={<DevicesIcon />}

        />
        <Menu.Item
          to="/member/extra/infos"
          primaryText="注册积分"
          leftIcon={<DevicesIcon />}

        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuReport")}
        isOpen={state.menuReport}
        name="报表"
        icon={<CardMembershipIcon />}

      >
        <Menu.Item
          to="/export"
          primaryText="报表导出"
          leftIcon={<LibraryBooksIcon />}

        />
        <Menu.Item
          to="/report"
          primaryText="消费明细报表"
          leftIcon={<UserIcon />}

        />
        <Menu.Item
          to="/invitations"
          primaryText="店铺专属二维码统计"
          leftIcon={<UserIcon />}

        />
      </SubMenu>
      <SubMenu
        key="权限管理"
        handleToggle={() => handleToggle("menuPermission")}
        isOpen={state.menuPermission}
        name="权限管理"
        icon={<VpnKeyIcon />}

      >
        {manage_admins ? (
          <Menu.Item
            to="/admins"
            primaryText="管理员"
            leftIcon={<UserIcon />}

          />
        ) : null}
        {manage_roles ? (
          <Menu.Item
            to="/roles"
            primaryText="角色"
            leftIcon={<VpnKeyIcon />}

          />
        ) : null}
      </SubMenu>
    </MenuContainer>
  );
};

export default MyMenu;
