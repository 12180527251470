import {
	Datagrid,
	SimpleList,
	TextField,
	ListContextProvider,
	useListController,
	ExportButton,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';

import { FullNameField, Pagination } from '../components';

export const MemberBirthdayWeekList = (props) => {
	const isSmall = useMediaQuery('(max-width:600px)');

	return (
		<ListContextProvider
			label='会员本周生日'
			title='会员本周生日'
			value={useListController({
				basePath: props.match?.path,
				resource: 'member/birthdays',
				filter: { type: 1 },
			})}
		>
			<div style={{ display: 'flex' }}>
				<div style={{ flex: '1' }}>
					<div></div>
				</div>
				<div style={{ margin: '20px auto' }}>
					<ExportButton resource='birthday/week' />
				</div>
			</div>
			{isSmall ? (
				<SimpleList
					primaryText={() => '姓名及生日'}
					secondaryText={(record) => `${record.name} ${record.birthday}`}
				/>
			) : (
				<Datagrid>
					<FullNameField />
					<TextField source='name' label='姓名' />
					<TextField source='mobile' label='手机号' />
					<TextField source='birthday' label='生日' />
					<TextField source='level.data.name.zh' label='会员等级' />
					<TextField source='points' label='积分' />
				</Datagrid>
			)}
			<Pagination />
		</ListContextProvider>
	);
};
