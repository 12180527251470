import { RichTextInput } from "ra-input-rich-text";
import { useEffect, useRef, useState } from "react";
import {
  Form,
  SaveButton,
  useCreate,
  useGetList,
  useUpdate,
} from "react-admin";
import { TopBarActions } from "../components";

const MemberRights = () => {
  const { data } = useGetList("settings");
  const [onCreate] = useCreate();
  const [onUpdate] = useUpdate();
  const [formData, setData] = useState();
  const initializeForm = useRef();
  const onSubmit = useRef();

  initializeForm.current = () => {
    if (data && Object.keys(data).length > 0) {
      console.log("🚀 ~ MemberRights ~ data:", data);
      let ugId, zhId;
      ugId = Object.keys(data).find(
        (id) => data[id]?.key === "ug_member_rights",
      );
      zhId = Object.keys(data).find(
        (id) => data[id]?.key === "zh_member_rights",
      );
      setData({
        ug_member_rights: data[ugId]?.value,
        zh_member_rights: data[zhId]?.value,
        zhId,
        ugId,
      });
    }
  };

  onSubmit.current = async (inputData) => {
    let zhData = {
      key: "zh_member_rights",
      value: inputData.zh_member_rights,
    };
    let ugData = {
      key: "ug_member_rights",
      value: inputData.ug_member_rights,
    };
    if (formData && formData.zhId) {
      await onUpdate("settings", formData.zhId, zhData);
    } else {
      await onCreate("settings", zhData);
    }
    if (formData && formData.ugId) {
      await onUpdate("settings", formData.ugId, ugData);
    } else {
      await onCreate("settings", ugData);
    }
  };

  useEffect(() => {
    initializeForm.current();
  }, [data]);

  return (
    <Form
      defaultValues={formData}
      onSubmit={onSubmit.current}
      actions={<TopBarActions />}
    >
      <RichTextInput source="zh_member_rights" label="权益说明（中文）" />
      <RichTextInput source="ug_member_rights" label="权益说明（维文）" />
      <SaveButton variant="contained" color="primary" type="submit">
        保存
      </SaveButton>
    </Form>
  );
};

export default MemberRights;
