import {
	BulkDeleteButton,
	BulkExportButton,
	Create,
	Datagrid,
	DeleteButton,
	Edit,
	EditButton,
	ImageField,
	ImageInput,
	List,
	NumberField,
	NumberInput,
	SimpleForm,
	SimpleList,
	TextField,
	TextInput,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CreateToolbar, TopBarActions } from '../components';
import { imageInputFormat } from '../constants';
import { Fragment } from 'react';

const UgTitle = styled('span')({
	fontFamily: 'UgFont',
});

export const MemberLevelsList = (props) => {
	const isSmall = useMediaQuery('(max-width:600px)');

	const PostBulkActionButtons = (props) => (
		<Fragment>
			<BulkExportButton {...props} />
			{/* default bulk delete action */}
			<BulkDeleteButton {...props} />
		</Fragment>
	);

	return (
		<List {...props}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => (
						<span>
							{record.name?.zh}{' '}
							<UgTitle>{record.name?.ug}</UgTitle>
						</span>
					)}
					secondaryText={(record) => record.level_points}
				/>
			) : (
				<Datagrid  bulkActionButtons={<PostBulkActionButtons />}>
					<TextField source='name.zh' label='中文名称' />
					<TextField
						source='name.ug'
						label='维文名称'
					/>
					<NumberField source='level_points' label='所需积分' />
					<ImageField source='image.zh' label='中文图片' />
					<ImageField source='image.ug' label='维文图片' />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			)}
		</List>
	);
};

export const MemberLevelsEdit = (props) => {
	return (
		<Edit {...props} title='编辑等级' actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name.zh' label='中文名称' />
				<TextInput source='name.ug' label='维文名称' />
				<NumberInput source='level_points' label='所需积分' />
				<ImageInput
					source='image.zh'
					label='中文图片'
					accept='image/*'
					format={imageInputFormat}
				>
					<ImageField source='src' />
				</ImageInput>
				<ImageInput
					source='image.ug'
					label='维文图片'
					accept='image/*'
					format={imageInputFormat}
				>
					<ImageField source='src' />
				</ImageInput>
			</SimpleForm>
		</Edit>
	);
};

export const MemberLevelsCreate = (props) => {
	return (
		<Create {...props} title='新增等级' actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name.zh' label='中文名称' />
				<TextInput source='name.ug' label='维文名称' />
				<NumberInput source='level_points' label='所需积分' />
				<ImageInput source='image.zh' label='中文图片' accept='image/*'>
					<ImageField source='src' />
				</ImageInput>
				<ImageInput source='image.ug' label='维文图片' accept='image/*'>
					<ImageField source='src' />
				</ImageInput>
			</SimpleForm>
		</Create>
	);
};
